import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, message, Modal, Select } from 'antd'

import { FormItemUpload } from '@/components/FormItemUpload'
import { fileValidate, formAutoTrimOnBlur } from '@/utils/utils'
import { PLAT_FORM_OPTS } from '@biz/consts/consts'
import { apiAddShop, apiEditShop } from './apis'
import { handleAuthorization } from './handleAuthorization'
import { IRecord, TShopValues } from './interface'
import { popupSlot } from './utils'

interface IShopModalProps {
  /** 传入则表示编辑 */
  record?: IRecord
  /** 编辑成功回调 */
  onEditSuccess?: (values: Pick<TShopValues, 'shopName' | 'logoUrl'>) => void
  /** 新增成功回调 */
  onSuccess?: () => void
}

const updatePopup = popupSlot.insert(null)

export const ShopModal = Object.assign(
  ({ record, onEditSuccess, onSuccess, destroy }: IShopModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [loading, setLoading] = useState(false)
    const [platForm, setPlatForm] = useState<string | number>(record ? record.platForm : '')

    const [form] = Form.useForm<Omit<TShopValues, 'url'> & { subdomain: string }>()
    const initialFormValues = useMemo<Omit<TShopValues, 'url'> & { subdomain: string }>(
      () => ({
        platForm: record?.platForm || 1,
        shopName: record?.shopName || '',
        logoUrl: record?.logoUrl || '',
        subdomain:
          record && record.url
            ? record.url
                .trim()
                .replace(/^https?:\/\//, '')
                .replace(/\.myshopify\.com$/, '')
            : '',
      }),
      [record],
    )

    return (
      <Modal
        title={record ? t('ShopList.bian-ji-shang-dian') : t('ShopList.xin-zeng-shang-dian')}
        width={600}
        onOk={async () => {
          let values = await form.validateFields()
          values = form.getFieldsValue(true)
          const shopName = values.shopName.trim()
          const logoUrl = values.logoUrl
          // 编辑
          if (record) {
            try {
              setLoading(true)
              if (record.shopName !== shopName || (record.logoUrl || '') !== logoUrl) {
                await apiEditShop({ sysShopId: record.sysShopId, shopName, logoUrl: logoUrl || '' })
                onEditSuccess?.({ shopName, logoUrl })
                message.success(t('2-common.cao-zuo-cheng-gong'))
              }
              onHide()
            } catch (err) {
              setLoading(false)
              throw err
            }
          }
          // 新增
          else {
            try {
              setLoading(true)
              const url = `https://${values.subdomain.trim()}.myshopify.com`
              if (values.platForm === 2) {
                await apiAddShop({ platForm: values.platForm, shopName, logoUrl })
                onSuccess?.()
                onHide()
              } else {
                await handleAuthorization({ platForm: values.platForm, shopName, logoUrl, url })
              }
            } catch (err) {
              setLoading(false)
              throw err
            } finally {
              setLoading(false)
            }
          }
        }}
        okText={record ? undefined : platForm !== 2 ? t('ShopList.shou-quan') : '新增'}
        okButtonProps={{ loading }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Form
          form={form}
          labelCol={{ span: 5 }}
          initialValues={initialFormValues}
          onBlur={e => formAutoTrimOnBlur({ event: e, form, includeField: ['shopName', 'subdomain'] })}
        >
          <Form.Item label={t('ShopList.ping-tai')} name="platForm" rules={[{ required: true }]}>
            <Select
              popupMatchSelectWidth={false}
              disabled={!!record}
              options={PLAT_FORM_OPTS}
              onChange={value => {
                setPlatForm(value)
              }}
            />
          </Form.Item>
          <Form.Item label={t('ShopList.shang-dian-ming-cheng')} name="shopName" rules={[{ required: true }]}>
            <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
          </Form.Item>
          <Form.Item label={t('ShopList.shang-jia-logo')} name="logoUrl">
            <FormItemUpload<false>
              maxCount={1}
              listType="picture-card"
              reqData={{ moduleType: 1 }}
              accept={fileValidate.IMG_ACCEPT}
            >
              + Upload
            </FormItemUpload>
          </Form.Item>
          {platForm !== 2 && (
            <Form.Item label={t('ShopList.shang-dian-di-zhi')} name="subdomain" rules={[{ required: true }]}>
              <Input
                addonBefore="https://"
                addonAfter=".myshopify.com"
                disabled={!!record}
                placeholder={t('ShopList.mai-jia-ping-tai-shang-de-zhang-hao')}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    )
  },
  {
    open: (props?: IShopModalProps) => {
      updatePopup(<ShopModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
